<template>
  <v-data-table
    :search="search"
    :headers="tableHeaders"
    :items="menuList"
    :loading="loading"
    item-key="id"
  >
    <template #top>
      <v-text-field label="Search" v-model="search" outlined />
    </template>

    <template #[`item.price`]="{ item }">
      <span>{{ Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(item.price) }}</span>
    </template>

    <template #[`item.actions`]="{ item }">
      <RestaurantMenuDialog
        @submit="change"
        :menu-id="item.id"
        :restaurant-id="restaurantId"
        :value="{ name: item.name, price: item.price, description: item.description }"
        :key="`EditMenu${item.id}`"
      >
        <template #activator="{ on }">
          <v-btn class="mr-3" v-on="on" small><v-icon small left>mdi-pencil</v-icon>Edit</v-btn>
        </template>
      </RestaurantMenuDialog>
      <RestaurantMenuDeleteDialog @change="change" :restaurant-id="restaurantId" :menu-id="item.id">
        <template #activator="{ on }">
          <v-btn v-on="on" small><v-icon small left>mdi-delete</v-icon>Delete</v-btn>
        </template>
      </RestaurantMenuDeleteDialog>
    </template>
  </v-data-table>
</template>

<script>
import firebase from '@/plugins/firebase'
import RestaurantMenuDialog from './RestaurantMenuDialog'
import RestaurantMenuDeleteDialog from './RestaurantMenuDeleteDialog'

export default {
  name: 'RestaurantMenuTable',

  components: {
    RestaurantMenuDialog,
    RestaurantMenuDeleteDialog
  },

  props: {
    'restaurant-id': {
      type: String,
      required: true
    }
  },

  data () {
    return ({
      loading: false,
      search: '',

      tableHeaders: [
        { text: 'Item Name', value: 'name' },
        { text: 'Price', value: 'price' },
        { text: 'Actions', value: 'actions', sortable: false, filterable: false }
      ],

      menuList: []
    })
  },

  computed: {},

  methods: {
    getMenuList (restaurantId) {
      return new Promise((resolve, reject) => {
        firebase.firestore().collection(`/restaurants/${restaurantId}/products`).get()
          .then(querySnapshot => {
            var results = querySnapshot.docs.map(doc => (
              Object.assign(doc.data(), { id: doc.id })
            ))
            resolve(results)
          })
          .catch(e => { reject(e) })
      })
    },
    change () {
      this.$emit('change')
    }
  },

  async mounted () {
    this.$data.loading = true
    try {
      this.$data.menuList = await this.getMenuList(this.restaurantId)
    } catch (e) {
      this.$emit('error', e)
    }
    this.$data.loading = false
  }
}
</script>
