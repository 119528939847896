<template>
  <div style="text-align: center">
    <h3 class="text-h5 mb-4">First, tell us a little bit about your restaurant (or café, or bar, or bistro, etc)</h3>
    <v-form @submit.prevent="submit">
      <v-text-field
        class="text-h6"
        label="Restaurant Name"
        prepend-inner-icon="mdi-feather"
        required
        v-model="restaurantData.name"
        outlined
        height="30px"
      />
      <v-text-field prepend-inner-icon="mdi-mailbox" label="Address" v-model="restaurantData.address" hint="Physical address. You can leave blank if your business doesn't have any physical location." />
      <v-text-field prepend-inner-icon="mdi-phone" label="Phone Number" v-model="restaurantData.contacts.phone" hint="The phone number on which your business can be reached by your customers. If you don't want to show your phone number, leave this blank." />
      <v-text-field prepend-inner-icon="mdi-instagram" label="Instagram URL" placeholder="https://instagram.com/johnsmith" v-model="restaurantData.contacts.instagram" hint="Please input the whole address (either long or shortened), not just the username. If you don't have Instagram, leave this blank."/>
      <v-text-field prepend-inner-icon="mdi-facebook" label="Facebook URL" placeholder="https://facebook.com/johnsmith" v-model="restaurantData.contacts.facebook" hint="Please input the whole address (either long or shortened), not just the username. If you don't have Facebook, you can leave this blank." />
      <v-text-field prepend-inner-icon="mdi-whatsapp" label="WhatsApp Number" v-model="restaurantData.contacts.whatsApp" hint="Your WhatsApp phone number. If you don't have WhatsApp leave blank." />
      <v-text-field prepend-inner-icon="mdi-web" label="Website" v-model="restaurantData.contacts.website" hint="Your website address. If you don't have a website, leave this blank." />

      <v-btn class="mt-2" color="primary" type="submit" :disabled="!readyToSubmit || loading" :loading="loading" large>Continue</v-btn>
    </v-form>
  </div>
</template>

<script>
import firebase from '@/plugins/firebase'

export default {
  name: 'Step1',

  computed: {
    readyToSubmit () {
      return this.$data.restaurantData.name && this.$data.restaurantData.name.length
    },
    url () {
      return this.$data.restaurantData.name.replace(/[\W_]+/g, '-').toLowerCase()
    }
  },

  data () {
    return ({
      loading: false,
      restaurantData: {
        name: '',
        address: null,
        contacts: {
          instagram: null,
          whatsApp: null,
          facebook: null,
          phone: null,
          email: null,
          website: null
        }
      }
    })
  },

  methods: {
    submit () {
      this.$data.loading = true
      firebase.firestore().collection('restaurants/').add(
        Object.assign({
          author: this.$store.getters['auth/user'].uid,
          url: this.url
        }, { dateAdded: firebase.firestore.FieldValue.serverTimestamp() }, this.$data.restaurantData)
      )
        .then(docRef => {
          this.$store.commit('restoManage/setNewlyCreatedRestaurantId', docRef.id)
          this.$emit('submit')
        })
        .catch(e => this.$emit('error', e))
        .finally(() => { this.$data.loading = false })
    }
  }
}
</script>
