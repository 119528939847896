<template>
  <div>
    <h3 class="text-h5 text-center">Your restaurant is now registered. Do you want to make your restaurant public now?</h3>
    <v-form @submit.prevent="handleSubmit">
      <v-radio-group v-model="publishNow">
        <v-radio :value="true">
          <template #label>
            <div class="text-h6">Publish now</div><br />
            <div>
              I'm ready for my restaurant to appear in the list of restaurants
              and be available for AmborJo visitors to view.
            </div>
          </template>
        </v-radio>
        <v-radio :value="false">
          <template #label>
            <div class="text-h6">Publish later</div><br />
            <div>To publish, click your profile photo in the top right corner of the screen and then click "Manage Restaurants"</div>
          </template>
        </v-radio>
      </v-radio-group>

      <v-btn color="primary" large type="submit">Create my restaurant</v-btn>
      <p><small>You'll be taken to the restaurant management page.</small></p>
    </v-form>
  </div>
</template>

<script>
import firebase from '@/plugins/firebase'

export default {
  name: 'PublishNewRestaurant',

  data () {
    return ({
      submitting: false,
      publishNow: true
    })
  },

  computed: {
    user () {
      return this.$store.getters['auth/user']
    },
    newlyCreatedRestaurantid () {
      return this.$store.getters['restoManage/newlyCreatedRestaurantId']
    },
    docPath () {
      return `/restaurants/${this.newlyCreatedRestaurantid}/`
    }
  },

  methods: {
    insertToDatabase () {
      return new Promise((resolve, reject) => {
        firebase.firestore().doc(this.docPath).set({ published: this.publishNow, author: this.user.uid }, { merge: true })
          .then(() => { resolve() })
          .catch(e => { reject(e) })
      })
    },

    async handleSubmit () {
      this.$data.submitting = true
      try {
        await this.insertToDatabase()
        this.$emit('submit')
      } catch (e) {
        this.$emit('error', e)
      }
      this.$data.submitting = false
    }
  }
}
</script>
