<template>
  <v-dialog width="800px" v-model="dialogOpen" persistent>
    <template v-slot:activator="slotProps">
      <slot name="activator" v-bind="slotProps">
        <v-btn color="primary">
          <v-icon left>mdi-plus</v-icon> New Item
        </v-btn>
      </slot>
    </template>
    <v-card>
      <v-form ref="menuDialogForm" @submit.prevent="handleSubmit">
        <v-card-title>New Menu Item</v-card-title>
        <v-card-text>
          <div class="d-flex">
            <div class="flex-grow-1"><v-text-field :disabled="submitting" outlined label="Item name" v-model="internalValue.name" /></div>
            <div class="px-1">&nbsp;</div>
            <div class="flex-shrink-1">
              <v-text-field :disabled="submitting" outlined label="Price" prefix="Rp" v-model="internalValue.price" />
            </div>
          </div>
          <v-textarea outlined :disabled="submitting" label="Description" v-model="internalValue.description" />
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="primary" :disabled="submitting" @click.prevent.stop="dialogOpen = false" text class="ml-1">Cancel</v-btn>
          <v-btn color="primary" :disabled="submitting" type="submit" text class="ml-1">Submit</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import firebase from '../../plugins/firebase'

export default {
  props: {
    value: {
      default: () => ({
        name: '',
        primaryPhoto: '',
        price: '',
        description: ''
      })
    },

    'restaurant-id': {
      required: true,
      type: String
    },

    'menu-id': {
      required: false,
      type: String
    }
  },

  data () {
    return ({
      submitting: false,
      dialogOpen: false,
      internalValue: this.value
    })
  },

  computed: {
    firebaseData () {
      var o = {
        name: this.$data.internalValue.name,
        price: parseInt(this.$data.internalValue.price),
        description: this.$data.internalValue.description
      }

      return o
    }
  },

  methods: {
    async handleSubmit () {
      this.$data.submitting = true

      var submitter = this.menuId ? this.submitEditedMenu : this.submitNewMenu
      try {
        await submitter()
        this.$emit('submit')
      } catch (e) {
        this.$emit('error', e)
      }

      if (!this.menuId) this.internalValue = this.value

      this.$data.submitting = false
      this.$data.dialogOpen = false
    },

    submitEditedMenu (menuId) {
      return new Promise((resolve, reject) => {
        firebase.firestore().doc(`/restaurants/${this.restaurantId}/products/${menuId}`).set(this.firebaseData)
          .then(() => { resolve() })
          .catch(e => { reject(e) })
      })
    },
    submitNewMenu () {
      return new Promise((resolve, reject) => {
        firebase.firestore().collection(`/restaurants/${this.restaurantId}/products`).add(this.firebaseData)
          .then(() => { resolve() })
          .catch(e => { reject(e) })
      })
    }
  }
}
</script>
