<template>
  <div class="text-center">
    <p class="text-h5">When are you guys open?</p>
    <p>When you're done, click <span class="text-uppercase">continue</span> button below</p>
    <v-form @submit.prevent="submit">
      <v-simple-table>
        <template #default>
          <!-- <thead>
            <tr>
              <th>Day</th>
              <th>Open</th>
              <th>Opening Time</th>
              <th>Closing Time</th>
            </tr>
          </thead> -->
          <tbody>
            <tr v-for="(day, n) in businessHours" :key="day.name">
              <td v-text="day.name" />
              <td>
                <div><v-switch @change="resetOpenTime(n, $event)" v-model="day.isOpen" :label="day.isOpen ? 'Open' : 'Closed'"/></div>
              </td>
              <td>
                <TimePicker v-model="day.openingTime" :disabled="!day.isOpen" label="Opening Time" />
              </td>
              <td>
                <TimePicker v-model="day.closingTime" :disabled="!day.isOpen" label="Closing Time" />
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-textarea v-model="notes" outlined label="Additional notes" />
      <v-btn color="primary" type="submit" :loading="submitting" :disabled="submitting" large>Continue</v-btn>
    </v-form>
  </div>
</template>

<script>
import firebase from '@/plugins/firebase'
import TimePicker from '@/components/TextboxTimepicker'

export default {
  name: 'BusinessHours',

  components: {
    TimePicker
  },

  computed: {
    user () {
      return this.$store.getters['auth/user']
    },
    newlyCreatedRestaurantid () {
      return this.$store.getters['restoManage/newlyCreatedRestaurantId']
    },
    docPath () {
      return `/restaurants/${this.newlyCreatedRestaurantid}/`
    }
  },

  data () {
    return ({
      submitting: false,
      businessHours: [
        {
          name: 'Sunday',
          isOpen: false,
          openingTime: null,
          closingTime: null
        },
        {
          name: 'Monday',
          isOpen: true,
          openingTime: '09:00',
          closingTime: '22:00'
        },
        {
          name: 'Tuesday',
          isOpen: true,
          openingTime: '09:00',
          closingTime: '22:00'
        },
        {
          name: 'Wednesday',
          isOpen: true,
          openingTime: '09:00',
          closingTime: '22:00'
        },
        {
          name: 'Thursday',
          isOpen: true,
          openingTime: '09:00',
          closingTime: '22:00'
        },
        {
          name: 'Friday',
          isOpen: true,
          openingTime: '09:00',
          closingTime: '22:00'
        },
        {
          name: 'Saturday',
          isOpen: false,
          openingTime: null,
          closingTime: null
        }
      ],
      notes: ''
    })
  },

  methods: {
    resetOpenTime (dayNo, checked) {
      if (!checked) {
        this.$data.businessHours[dayNo].openingTime = null
        this.$data.businessHours[dayNo].closingTime = null
      }
    },

    insertIntoDatabase () {
      return new Promise((resolve, reject) => {
        firebase.firestore().doc(this.docPath).set({
          businessHours: {
            hours: this.$data.businessHours,
            notes: this.$data.notes
          }
        }, { merge: true })
          .then(() => { resolve() })
          .catch(e => { reject(e) })
      })
    },

    async submit () {
      this.$data.submitting = true
      try {
        await this.insertIntoDatabase()
        this.$emit('submit')
      } catch (e) {
        this.$emit('error', e)
      }
      this.$data.submitting = false
    }
  }
}
</script>
