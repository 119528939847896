var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"search":_vm.search,"headers":_vm.tableHeaders,"items":_vm.menuList,"loading":_vm.loading,"item-key":"id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{attrs:{"label":"Search","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(item.price)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('RestaurantMenuDialog',{key:("EditMenu" + (item.id)),attrs:{"menu-id":item.id,"restaurant-id":_vm.restaurantId,"value":{ name: item.name, price: item.price, description: item.description }},on:{"submit":_vm.change},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-3",attrs:{"small":""}},on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-pencil")]),_vm._v("Edit")],1)]}}],null,true)}),_c('RestaurantMenuDeleteDialog',{attrs:{"restaurant-id":_vm.restaurantId,"menu-id":item.id},on:{"change":_vm.change},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":""}},on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-delete")]),_vm._v("Delete")],1)]}}],null,true)})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }