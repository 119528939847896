<template>
  <v-dialog persistent width="300px" v-model="dialogOpen">
    <submitting v-model="deleting">Deleting</submitting>
    <template v-slot:activator="slotProps">
      <slot name="activator" v-bind="slotProps" />
    </template>

    <v-card>
      <v-card-title>Delete from menu?</v-card-title>
      <v-card-text>{{ menuName }}</v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn text @click="dialogOpen = false">Cancel</v-btn>
        <v-btn text color="primary" @click="deleteItem">Delete</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import firebase from '../../plugins/firebase'
import SubmittingVue from '../Submitting.vue'

export default {
  name: 'RestaurantMenuDeleteDialog',

  components: {
    submitting: SubmittingVue
  },

  props: {
    'menu-id': {
      required: true,
      type: String
    },
    'restaurant-id': {
      required: false,
      type: String
    },
    'menu-name': String
  },

  data () {
    return ({
      dialogOpen: false,
      deleting: false
    })
  },

  methods: {
    async deleteItem () {
      this.$data.deleting = true
      try {
        await this.commenceDeletion()
        this.$emit('change')
      } catch (e) {
        this.$emit('error', e)
      }
      this.$data.deleting = false
    },
    commenceDeletion () {
      return new Promise((resolve, reject) => {
        firebase.firestore().doc(`/restaurants/${this.restaurantId}/products/${this.menuId}`).delete()
          .then(() => { resolve() })
          .catch(e => { reject(e) })
      })
    }
  }
}
</script>
