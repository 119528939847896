<template>
  <v-container>
    <v-row>
      <v-col cols="2" xs="4" sm="4" md="2" lg="2" xl="1" v-for="word in dictionary" :key="word">
        <v-checkbox :value="word" :label="word" v-model="internalValue" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'AmenitiesSelector',

  props: {
    value: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    internalValue: {
      get () {
        return this.value
      },
      set (v) {
        return this.$emit('input', v)
      }
    }
  },

  data () {
    return ({
      dictionary: [
        'High-class', 'Accepts debit/credit cards', 'Cash only', 'Family-friendly', 'Alcohol', 'Non-smoking', 'Smoking room', 'Air conditioned',
        'Halal', 'Delivery', 'Dine-in', 'Takeaway', 'Accepts e-wallet',
        'Nice view', 'Cozy', 'Romantic', 'Breakfast', 'Lunch', 'Dinner', 'Snacks', 'Coffee', 'Trendy',
        'Rustic', 'Live music', 'Western', 'Korean', 'Japanese', 'Chinese', 'Minahasan', 'Sumatran', 'Javanese', 'Makassarese'
      ].sort()
    })
  }
}
</script>
