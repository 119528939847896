<template>
  <v-container>
    <h1 class="text-h4 mb-2">Register a New Restaurant</h1>
    <v-stepper class="mb-3 elevation-0" v-model="step" alt-labels>
      <v-stepper-header>
        <template v-for="(step, n) in steps">
          <v-stepper-step :complete="step > n + 1" :step="n + 1" :key="`step${n}`">{{ step.name }}</v-stepper-step>
          <v-divider v-if="n !== steps.length - 1" :key="n" />
        </template>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <Step1 @submit="nextPage"/>
        </v-stepper-content>
        <v-stepper-content step="2">
          <Step2 @submit="nextPage" />
        </v-stepper-content>
        <v-stepper-content step="3">
          <Step3 @submit="nextPage" />
        </v-stepper-content>
        <v-stepper-content step="4">
          <Step4 @submit="nextPage" />
        </v-stepper-content>
        <v-stepper-content step="5">
          <Step5 @submit="nextPage" />
        </v-stepper-content>
        <v-stepper-content step="6">
          <Step6 @submit="nextPage" />
        </v-stepper-content>
        <v-stepper-content step="7">
          <Step7 @submit="nextPage" />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import Step5 from './Step5'
import Step6 from './Step6'
import Step7 from './Step7'

export default {
  name: 'NewRestaurant',

  props: {
    step: Number
  },

  components: {
    Step1, Step2, Step3, Step4, Step5, Step6, Step7
  },

  computed: {
    newlyCreatedRestaurantId () {
      return this.$store.getters['restoManage/newlyCreatedRestaurantId']
    }
  },

  data () {
    return ({
      steps: [
        { name: 'Basic Info' },
        { name: 'Primary Photo' },
        { name: 'Gallery' },
        { name: 'Restaurant Menu' },
        { name: 'Business Hours' },
        { name: 'Other Info' },
        { name: 'Publish' }
      ],
      c: null
    })
  },

  methods: {
    nextPage () {
      this.$router.push({ path: `/management/new-restaurant/${this.step + 1}` })
    }
  },

  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (to.params.step > vm.steps.length) {
        vm.$store.commit('restoManagement/setNewlyCreatedRestaurantId', null)
        vm.$router.push({ path: `/management/restaurants/${vm.newlyCreatedRestaurantId}` })
      }
    })
  }
}
</script>
