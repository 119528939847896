<template>
  <div style="text-align: center">
    <v-form @submit.prevent="handleSubmit">
      <h3 class="text-h5">Now, upload a photo for your restaurant</h3>
      <p>For best results, pick a landscape image.</p>

      <div class="mb-10">
        <v-img
          class="d-inline-block" :src="photoURL || require('@/assets/img/image-upload-placeholder.png')"
          :aspect-ratio="photoURL ? 16 / 9 : ''"
          :contain="!photoURL"
          :height="!photoURL ? '250px' : '400px'"
          :width="!photoURL ? '50vh' : ''"
        /><br />
        <FirebaseImageUploaderDialog v-model="photoURL" :storage-path="`/users/${user.uid}/restaurants/${restaurantId}`">
          <template #activator="{ on }">
            <v-btn v-on="on"><v-icon left>mdi-camera</v-icon>Choose Image</v-btn>
          </template>
        </FirebaseImageUploaderDialog>
      </div>
      <!-- <img src="@/assets/img/image-upload-placeholder.png" /> -->
      <v-btn color="primary" x-large type="submit" :loading="loading" :disabled="!photoURL || loading">Continue</v-btn>
    </v-form>
  </div>
</template>

<script>
import firebase from '@/plugins/firebase'
import FirebaseImageUploaderDialog from '@/components/ImageUploader/FirebaseImageUploader'

export default {
  name: 'NewRestaurantStep2',

  components: { FirebaseImageUploaderDialog },

  data () {
    return ({
      loading: false,
      photoURL: null
    })
  },

  computed: {
    restaurantId () {
      return this.$store.getters['restoManage/newlyCreatedRestaurantId']
    },
    user () {
      return this.$store.getters['auth/user']
    }
  },

  methods: {
    savePhotoInDatabase () {
      return new Promise((resolve, reject) => {
        firebase.firestore().doc(`/restaurants/${this.restaurantId}`)
          .set({
            primaryPhoto: this.$data.photoURL,

            // ALWAYS embed the author uid.
            author: this.user.uid
          }, { merge: true })
          .then(() => { resolve() })
          .catch(e => { reject(e) })
      })
    },

    async handleSubmit () {
      this.$data.loading = true
      try {
        await this.savePhotoInDatabase()
        this.$emit('submit')
      } catch (e) {
        this.$emit('error', e)
      }
      this.$data.loading = false
    }
  }
}
</script>
