<template>
  <div>
    <h2 class="text-h5 text-center">Just a few more things...</h2>
    <v-form @submit.prevent="submit">
      <div>
        <h3 class="text-h6"><v-icon left>mdi-cash-multiple</v-icon>Price Range</h3>
        <p>How much does the average costumer spend on your establishment to have a good time?</p>
        <div class="d-flex align-baseline" style="width: 50vh">
          <v-text-field label="Min" prefix="Rp" />
          <span class="d-inline-block mx-4">&mdash;</span>
          <v-text-field label="Max" prefix="Rp" />
        </div>

        <div class="mt-3">
          <h3 class="text-h6"><v-icon left>mdi-room-service</v-icon>Services &amp; Amenities</h3>
          <p>Check the words that describe your establishment, or the services you provide.</p>
          <AmenitiesSelector v-model="amenities" />
        </div>
      </div>

      <v-btn type="submit" color="primary" :loading="submitting" :disabled="submitting" large>Continue</v-btn>
    </v-form>
  </div>
</template>

<script>
import firebase from '@/plugins/firebase'
import AmenitiesSelector from '@/components/RestaurantManagement/AmenitiesSelector'

export default {
  name: 'OtherInfo',

  components: { AmenitiesSelector },

  computed: {
    user () {
      return this.$store.getters['auth/user']
    },
    newlyCreatedRestaurantid () {
      return this.$store.getters['restoManage/newlyCreatedRestaurantId']
    },
    docPath () {
      return `/restaurants/${this.newlyCreatedRestaurantid}/`
    }
  },

  data () {
    return ({
      submitting: false,
      amenities: [],
      priceRange: {
        min: null,
        max: null
      }
    })
  },

  methods: {
    insertToDatabase () {
      var priceRange = {
        min: parseFloat(this.$data.priceRange.min),
        max: parseFloat(this.$data.priceRange.max)
      }
      return new Promise((resolve, reject) => {
        firebase.firestore().doc(this.docPath).set({
          features: this.$data.amenities,
          priceRange: priceRange,
          author: this.user.uid
        }, { merge: true })
          .then(() => { resolve() })
          .catch(e => { reject(e) })
      })
    },

    async submit () {
      this.$data.submitting = true
      try {
        await this.insertToDatabase()
        this.$emit('submit')
      } catch (e) {
        this.$emit('error', e)
      }
      this.$data.submitting = false
    }
  }
}
</script>
