<template>
  <div style="text-align: center">
    <h1 class="text-h5"> Now, upload images to the gallery</h1>
    <p>When you're done, click Continue</p>
    <div>
      <ImageUploader @input="refreshThumbnails" multiple :storage-path="storagePath">
        <template #activator="{ on }">
          <v-btn v-on="on" color="primary">
            <v-icon left>mdi-plus</v-icon>Upload New
          </v-btn>
        </template>
      </ImageUploader>
    </div>
    <FirebaseFirestoreGallery :collection-path="collectionPath" checkboxes :key="galleryKey"/>
    <div class="mt-4"><v-btn large color="primary" @click="submit">Continue</v-btn></div>
  </div>
</template>

<script>

import ImageUploader from '@/components/ImageUploader/FirebaseImageUploader'
import FirebaseFirestoreGallery from '@/components/Gallery/FirebaseFirestoreGallery'

export default {
  name: 'UploadGallery',
  components: {
    ImageUploader, FirebaseFirestoreGallery
  },

  computed: {
    user () {
      return this.$store.getters['auth/user']
    },
    newlyCreatedRestaurantId () {
      return this.$store.getters['restoManage/newlyCreatedRestaurantId']
    },
    collectionPath () {
      return `/restaurants/${this.newlyCreatedRestaurantId}/photos`
    },
    storagePath () {
      return `/users/${this.user.uid}/restaurants/${this.newlyCreatedRestaurantId}`
    }
  },

  data () {
    return ({
      galleryKey: (new Date()).toString()
    })
  },

  methods: {
    refreshThumbnails () {
      this.$data.galleryKey = (new Date()).toString()
    },

    submit () {
      this.$emit('submit')
    }
  }
}
</script>
