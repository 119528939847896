<template>
  <div>
    <div class="mb-2 text-center">
      <h1 class="text-h5">List your menu here.</h1>
      <p>When you're done, click <span class="text-uppercase">Continue</span> button below the table.</p>
    </div>
    <div class="mb-4">
      <RestaurantMenuDialog @submit="refreshTable" @error="handleError" :restaurant-id="newlyCreatedRestaurantId">
        <template #activator="{ on }">
          <v-btn rounded v-on="on" color="primary"><v-icon left>mdi-plus</v-icon>New Menu</v-btn>
        </template>
      </RestaurantMenuDialog>
    </div>
    <RestaurantMenuTable @change="refreshTable" :restaurant-id="newlyCreatedRestaurantId" :key="tableKey" />
    <div class="mt-5">
      <v-btn @click="submit" large color="primary">Continue</v-btn>
    </div>
  </div>
</template>

<script>
import RestaurantMenuTable from '@/components/RestaurantManagement/RestaurantMenuTable2'
import RestaurantMenuDialog from '@/components/RestaurantManagement/RestaurantMenuDialog'

export default {
  name: 'AddRestaurantMenu',

  components: { RestaurantMenuTable, RestaurantMenuDialog },

  computed: {
    newlyCreatedRestaurantId () {
      return this.$store.getters['restoManage/newlyCreatedRestaurantId']
    }
  },

  data () {
    return ({
      tableKey: Math.random()
    })
  },

  methods: {
    refreshTable () {
      this.$data.tableKey = Math.random()
    },

    handleError () {
      this.$store.commit('toast/setError', 'Error')
    },

    submit () {
      this.$emit('submit')
    }
  }
}
</script>
